import { BreedHealthConditions } from './BreedHealthConditions';
import Foundation from './foundation';
import debounce from 'lodash/debounce';
import { maybeAddDisclaimer } from './ConditionDisclaimers';
import { myevUrl } from '../util/helpers';
import { pushGTMCustomEvent } from './pushGTMCustomEvent';
import { toggleAutocompleteValuesBasedOnInput } from '../util/jQueryAutocompleteHelpers';

export class HealthConditions {
  static async init() {
    await HealthConditions.loadDependencies();
    const response = await fetch(myevUrl('health-and-breed-list'));
    const identifiableHealth = await response.json();
    const healthDisplayName = (health) => {
      return `${health.disorder_name} ${health.subdisorder_name}`;
    };
    const allHealthLabelsForAutocomplete = identifiableHealth.map(healthDisplayName);
    const instructionsHealthList = document.getElementById('instructions-health-list');
    const healthConditionAutocompleteInput = jQuery(document.getElementById('health-list'));
    const searchWrapper = jQuery(document.querySelector('.breed-search-form'));
    const searchFormSize = searchWrapper.find('.search-form-size');
    const searchButton = document.getElementById('search-health-list');
    const clearButton = document.getElementById('clear-health-list');

    if (clearButton) {
      clearButton.addEventListener('click', () => {
        healthConditionAutocompleteInput.val('');
        document.getElementById('health-list-results').innerHTML = '';
      });
    }

    const onSelect = (value) => {
      const health = identifiableHealth.filter((item) => {
        return healthDisplayName(item) === value;
      });

      if (!health.length) {
        const container = document.getElementById('health-list-results');
        return HealthConditions.renderNoResults(value, container);
      }

      HealthConditions.fetchAndRenderHealthConditions(health[0], value).catch((_error) => {
        // TODO: Notify Sentry once a client side helper is created
      });
    };

    searchButton.addEventListener('click', function () {
      onSelect(healthConditionAutocompleteInput.val());
    });

    healthConditionAutocompleteInput.on('keyup', async function (e) {
      if (e.keyCode === 13 || e.key === 'Enter') {
        onSelect(jQuery(this).val());
      }
    });

    const defaultHealthLabelsForAutocomplete =
      typeof window.embark_popular_health_conditions !== 'undefined' ? window.embark_popular_health_conditions : [];

    const topPadding =
      searchFormSize.length && searchFormSize.data('input-padding-top') ? searchFormSize.data('input-padding-top') : 0;
    healthConditionAutocompleteInput.autocomplete({
      source: defaultHealthLabelsForAutocomplete,
      delay: 0,
      minLength: 0,
      select(event, ui) {
        if (event.key === 'Enter') {
          return;
        }
        onSelect(ui.item.value);
      },
      appendTo: '.breed-search-form_input',
      position: {
        my: 'left+0 top+' + topPadding,
      },
    });

    const generateIdFromLabel = (label) => {
      return label
        .replace(/[^\w\s-]/g, '')
        .replace(/\s+/g, '-')
        .toLowerCase()
        .substring(0, 20);
    };

    healthConditionAutocompleteInput.autocomplete('instance')._renderItem = function (ul, item) {
      var li = jQuery('<li>')
        .attr({
          id: 'item-' + generateIdFromLabel(item.label),
          role: 'option',
        })
        .append(jQuery('<div>').text(item.label));

      return li.appendTo(ul);
    };

    healthConditionAutocompleteInput.autocomplete('widget').attr({
      'role': 'listbox',
      'aria-labelledby': 'health-list',
    });

    const toggleSources = () =>
      toggleAutocompleteValuesBasedOnInput(
        healthConditionAutocompleteInput,
        allHealthLabelsForAutocomplete,
        defaultHealthLabelsForAutocomplete,
        'Popular Searches'
      );
    healthConditionAutocompleteInput.on('focus', () => {
      toggleSources();
      healthConditionAutocompleteInput.autocomplete('search');
      instructionsHealthList.setAttribute('aria-hidden', false);
    });

    healthConditionAutocompleteInput.on('blur', () => {
      instructionsHealthList.setAttribute('aria-hidden', true);
    });

    healthConditionAutocompleteInput.on('input', debounce(toggleSources, 150));

    healthConditionAutocompleteInput.on('autocompleteopen', () => {
      let autocompleteMenu = healthConditionAutocompleteInput.autocomplete('widget');

      if (autocompleteMenu.length) {
        const menuId = autocompleteMenu.attr('id');
        healthConditionAutocompleteInput.attr({
          'aria-controls': menuId,
          'aria-expanded': true,
        });
      }
    });

    healthConditionAutocompleteInput.on('autocompleteclose', () => {
      healthConditionAutocompleteInput.removeAttr('aria-controls');
      healthConditionAutocompleteInput.attr('aria-expanded', false);
    });

    const menuWidth = searchFormSize.length
      ? searchFormSize.outerWidth()
      : healthConditionAutocompleteInput.outerWidth();
    // Set the autocomplete menu size as the input/wrapper.
    jQuery.ui.autocomplete.prototype._resizeMenu = function () {
      this.menu.element.outerWidth(menuWidth);
    };
  }

  static async fetchAndRenderHealthConditions(health, query) {
    const container = document.getElementById('health-list-results');
    container.innerHTML = '';

    const searchType = 'Health Condition Search';
    const eventProperties = {
      eventCategory: searchType,
      eventAction: query,
      eventURL: window.location.href,
    };

    let result;
    try {
      const response = await fetch(myevUrl(`/health-conditions/${health.health_id}`));
      result = await response.json();
      pushGTMCustomEvent(searchType, eventProperties);
    } catch (e) {
      eventProperties.eventAction += ' - no results';
      pushGTMCustomEvent(searchType, eventProperties);
      return HealthConditions.renderNoResults(query, container);
    }

    await HealthConditions.renderHealth(result, container, health);
    HealthConditions.renderSuggestions(health, container);
  }

  static async loadDependencies() {
    const { Accordion } = await import('foundation-sites/js/foundation.accordion');
    Foundation.plugin(Accordion, 'Accordion');
  }

  static async renderHealth(health, container, baseHealthQuery) {
    const { condition } = health;
    const accordion = jQuery('<ul class="accordion medium-12 small-12"/>');
    const item = jQuery('<li class="grid-x disease accordion-item" data-accordion-item/>');
    const heading = jQuery(`
        <a href="javascript:;" class="accordion-title medium-12 small-12 grid-x align-justify">
          <h5 class="medium-9 small-10 disease__name" >
            ${condition.disorder_name} ${condition.subdisorder_name}
          </h5>
         </a>
      `);

    const meta = jQuery('<div class="grid-x disease__meta accordion-content" data-tab-content/>');

    const metaDescription = jQuery(`
         <div class="medium-12">
            <h6 class="text-muted disease__lay-term">
             ${condition.lay_term} (${condition.category})
           </h6>
           <h6 class="text-muted disease__gene">Gene: ${condition.gene}</h6>
           <h6 class="text-muted disease__inheritance-type">
              Inheritance type: ${condition.moi}
           </h6>
        </div>
      `);

    const description = jQuery(
      `<p class="medim-12 disease__description">${condition.consumer_brief_description_required}</p>`
    );

    const citationsLinks = [1, 2, 3, 4, 5]
      .map((i) =>
        condition[`reference_${i}_text`]
          ? `<a target="_blank" rel="noreferrer noopener" class="disease__citation" href="${
              condition[`reference_${i}_link`]
            }">${condition[`reference_${i}_text`]}</a>`
          : ''
      )
      .join(' ');
    const citations = citationsLinks ? jQuery(`<h6 class="text-muted">Citations:</h6><p>${citationsLinks}</p>`) : '';

    meta.append(metaDescription, description, citations);

    if (window.embarkConditionDisclaimerStrings) {
      maybeAddDisclaimer(baseHealthQuery, meta, embarkConditionDisclaimerStrings, 'healthList');
    }

    item.append(heading, meta);
    accordion.append(item);
    jQuery(container).append(accordion);

    new Foundation.Accordion(accordion, {
      allowAllClosed: true,
    });
    // if any magellan links were added in JS enable them.
    if (jQuery('.disclaimer--header, .disclaimerDescriptionText').length) {
      jQuery(document).foundation();
    }
  }

  static renderSuggestions({ breed_display_names }, container) {
    if (container.getAttribute('data-hide-breed-suggestions') === 'true') {
      return;
    }
    const suggestions = document.createElement('ul');
    suggestions.classList.add('breed-suggestions');
    const heading = document.createElement('li');
    heading.innerHTML = '<h6>Seen in breeds:</h6>';
    suggestions.appendChild(heading);

    breed_display_names.split(',').forEach((breed) => {
      const suggestion = document.createElement('a');
      suggestion.dataset.breedName = breed;
      suggestion.innerText = breed;
      suggestion.addEventListener('click', HealthConditions.breedSuggestionClickHandler);
      const item = document.createElement('li');
      item.appendChild(suggestion);
      suggestions.appendChild(item);
    });

    container.appendChild(suggestions);
  }

  static async breedSuggestionClickHandler(e) {
    e.preventDefault();
    const { breedName } = e.target.dataset;
    await BreedHealthConditions.setupViewFor(breedName);

    // Scroll to the breed conditions form when a breed is clicked
    const breedConditionsFrom = document.querySelector('.breed-health-search');
    if (breedConditionsFrom) {
      jQuery('html,body').animate({
        scrollTop: breedConditionsFrom.offsetTop,
      });
    }
  }

  static renderNoResults(query, container) {
    container.innerHTML = `
      <div role="status">
        <span>No conditions found for ${query}. However, you will still receive results for all health conditions on our
        <a href='https://embarkvet.com/products/dog-health/health-conditions-list/' class="a-underlined">health list</a>.
        Please contact <a target="_blank" href="mailto:breeders@embarkvet.com" class="a-underlined">breeders@embarkvet.com</a> if you have questions.</span>
      </div>
    `;
  }
}
